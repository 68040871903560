import React, { useState } from "react"
import classnames from "classnames"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Component(s) */

import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import MailingListModal from "~components/footer/components/mailingListModal/mailingListModal"


import CrossSvgSrc from "~assets/images/close.svg"

/* Import Local Style(s) */
import "./newsletter.scss"

const NewsletterPage = ({ location }) => {
  

  const closeModal = () => {

  }

  return (
    <Page title="Newsletter" location={location}>
      <PageArticle title="Newsletter" className="newsletter-page">
        <div className="mailing-list--modal">
          <Link className="mailing-list_close" to="/">
            <img
              src={CrossSvgSrc}
              className="close"
              alt="cross"
              key="cross"
              exit="exit"
            />
          </Link>
          <MailingListModal />          
        </div>
      </PageArticle>
    </Page>
  )
}

export default NewsletterPage
